<template src="./galleries.html"></template>

<script>
import Http from '@/shared/http-config'
import draggable from 'vuedraggable'

export default {
  name: "Galleries",
  components: {draggable},
  data: function () {
    return {
      galleries: [],
      meta: {
        current_page: 1,
        total: 0,
        per_page: 9
      },
      per_page_options: [
        { value: 9, text: '9' },
        { value: 15, text: '15' },
        { value: 30, text: '30' },
        { value: 99999, text: 'Alle' },
      ]
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    this.getGalleries(1);
  },
  methods: {
    getGalleries (page) {
      let self = this;
      const params = '?page=' + page + '&per_page=' + self.meta.per_page;

      Http.request('GET', '/galleries' + params)
        .then(function (response) {
          self.galleries = response.data;
          self.meta = response.meta;
        });
    },
    deleteGallery (id, key) {
      let self = this;
      self.$bvModal.msgBoxConfirm("Möchstest du diese Galerie wirklich löschen?", {
          headerBgVariant: 'primary',
          title: 'Galerie löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer) {
            Http.request('DELETE', '/galleries/' + id).then(function () {
              self.galleries.splice(key, 1)
            });
          }
        });
    },
    saveSort () {
      Http.request('POST', '/galleries/sort', {data: this.galleries, meta: this.meta});
    },
  }
}
</script>

<style scoped>
  .card {
    min-height: 93%;
    border-radius: 0;
  }
  .card .card-text a {
    font-weight: 700;
    font-size: 1rem;
  }
  .card-img-top {
    max-height: 120px;
  }
  .card .card-body {
    padding: 0.75rem;
  }
</style>
